
import OpenLibraryReviews from '#app/components/desktop/open-library-reviews';
import { getUserId } from '#app/utils/auth.server';
import { BookServer } from '#app/utils/book/book.server';
import { prisma } from '#app/utils/db.server';
import { KeywordServer } from '#app/utils/keyword/keyword.server';
import { logger } from '#app/utils/logger';
import { RankingKeywordServer } from '#app/utils/ranking-keyword/ranking-keyword.server';
import { ReviewServer } from '#app/utils/review/review.server';
import { useOptionalUser } from '#app/utils/user';
import { type LoaderFunctionArgs, json } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';


export async function loader({ params, request }: LoaderFunctionArgs) {

  const url = new URL(request.url);
  const userId = await getUserId(request);
  const nextCursor = url.searchParams.get('nextCursor');
  const sort = (url.searchParams.get('sort') ?? 'latest')as unknown as 'latest' | 'recommend';
  const k = (params.keyword ?? '추천').replaceAll('-', '/');
  const keyword = await KeywordServer.getKeywordByStringAndUserId(k, userId);
  logger.info(`${keyword.name} open-library-keyword ------- START`, 'loader');


  if (keyword.name === '전체') {
    logger.info('전체 open-library-keyword ------- END', 'loader');
    const results = await ReviewServer.getReviewsWithAll({ nextCursor, sort });

    return json({
      keyword,
      pageInfo: {
        totalCount: results.totalCount,
      },
      relateKeywords: await RankingKeywordServer.getRankingKeywordByUser(userId),
      reviews       : await BookServer.openLibraryBookByReviewsAndUserId(results.reviews, userId),
      sort,
    });
  }
  else if(userId && keyword.name === '추천') {
    const user = await prisma.user.findFirstOrThrow({ 
      include: {followKeywords: { include: { keyword: true } }},
      where  : {id: userId } 
    });

    if (user.keywordId === null) { 
      logger.info('NO Career open-library-keyword ------- END', 'loader');
      return json({
        keyword,
        pageInfo: {
          totalCount: 0,
        },
        relateKeywords: [],
        reviews       : [],
        sort,
      });
    }

    const keywords = user.followKeywords.map((value) => value.keyword);

    logger.info('추천 open-library-keyword ------- END', 'loader');
    const results = await ReviewServer.getReviewsWithRecommend({
      keywords,
      nextCursor,
      sort,
    });

    return json({
      keyword,
      pageInfo: {
        totalCount: results.totalCount,
      },
      relateKeywords: await RankingKeywordServer.getRankingKeywordByUser(userId),
      reviews       : await BookServer.openLibraryBookByReviewsAndUserId(results.reviews, user.id),
      sort
    });
  }
  else {
    logger.info('키워드 open-library-keyword ------- END', 'loader');
    const reviews =  await ReviewServer.getReviewsWithKeyword({
      keyword,
      nextCursor,
      sort,
    });

    return json({
      keyword,
      pageInfo: {
        totalCount: reviews.totalCount,
      },
      relateKeywords: await RankingKeywordServer.getRankingKeywordByKeyword(keyword),
      reviews       : await BookServer.openLibraryBookByReviewsAndUserId(reviews.reviews, userId),
      sort,
    });
  }
}

export default function OpenLibraryKeyword() {
  const data = useLoaderData<typeof loader>();
  const u = useOptionalUser();

  return <OpenLibraryReviews isLogged={u?.isLoggedIn ?? false} loader={data} />;
}
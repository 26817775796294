import type { loader } from '#app/routes/_open-library+/open-library.$keyword';
import type { action as BookAction} from '#app/routes/action-book';
import type { OpenLibraryBookByReviewsAndUserIdOutput } from '#app/utils/book/book.server.interface';
import type { SerializeFrom } from '@remix-run/node';
import type { useLoaderData} from '@remix-run/react';

import { EmptyDescription } from '#app/routes/_my-library+/my-library.$userId.$library';
import { cn } from '#app/utils/misc';
import { screenSize } from '#app/utils/screen-size';
import { Link, useFetcher, useNavigate } from '@remix-run/react';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';

import RelateKeyword from '../relate-keyword';
import { LibraryItem } from './library-item';
import { UploadBookDialog } from './upload-book-dialog';


interface Props {
  isLogged: boolean;
  loader: ReturnType<typeof useLoaderData<typeof loader>>;
}
const OpenLibraryReviews: React.FC<Props> = ({ isLogged, loader }) => {
  const { inView, ref } = useInView({
    threshold: 0,
  });
  const { keyword, pageInfo, relateKeywords, reviews: init, sort } = loader;
  
  const [_reviews, _setReviews] = React.useState<SerializeFrom<OpenLibraryBookByReviewsAndUserIdOutput>>(init);


  const navigate = useNavigate();
  const bookActionFetcher = useFetcher<typeof BookAction>();
  const paginationFetcher = useFetcher<typeof loader>();
  const paginationFetcherRef = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    _setReviews(init);
  }, [init]);


  React.useEffect(() => {
    if (inView && pageInfo.totalCount > _reviews.length) {
      if(_reviews[_reviews.length -1]?.originId) {
        // searchParams.set('nextCursor', _reviews[_reviews.length -1]?.originId);
        // const qs = searchParams.toString();
        // history.replaceState(null, '', qs ? `?${qs}` : '/');
        paginationFetcher.submit(paginationFetcherRef.current, 
          { method: 'GET', preventScrollReset: false, }
        );
      }
    }
  }, [inView, pageInfo.totalCount]);

  React.useEffect(() => {
    if (!paginationFetcher.data) {
      return;
    }
    const arr = _reviews?.concat(
      paginationFetcher.data.reviews ?? []
    ) ?? [];
    const set = new Set(arr.map((review) => review.id));
    const reviews = [...set].map(id => arr.find(review => review.id === id)).filter(Boolean);
    _setReviews(reviews);
    paginationFetcher.data = undefined;
  }, [paginationFetcher.data, keyword.id]);


  React.useEffect(() => {
    if (!bookActionFetcher.data) {
      return;
    }

    _setReviews((reviews) => {
      return reviews.map(
        review => ({
          ...review,
          isLiked: bookActionFetcher.data?.book?.id === review.source.id ? bookActionFetcher.data?.isLike : review.isLiked,
        })
      );
    });
  }, [bookActionFetcher.data]);

  /**
   * - 28px: grid gap
   */
  const widthPx: number = screenSize(isMobile ? 16: 28).itemWidth;
  
  return (
    <>
      {
        _reviews?.length > 0
          ?
          <div className='grid grid-flow-row'>
            <section 
              className='items-end flex xs:gap-x-4 sm:gap-x-7 md:gap-x-7 xs:gap-y-10 sm:gap-y-10 md:gap-y-20 flex-wrap'
            >
              {
                _reviews.slice(0, 4)
                  .map(review =>
                    <bookActionFetcher.Form action='/action-book' key={`${keyword.id}+${review.id}+${review.owner.id}`} method='POST'>
                      <Link prefetch='intent' to={`/books/${review.bookId}`}>
                        <LibraryItem
                          isLoading={bookActionFetcher.state === 'loading'}
                          keyword={keyword}
                          onClick={(id) => navigate(`/books/${id}`)}
                          review={review}
                          tag='user'
                          widthPx={widthPx}
                        />
                      </Link>
                    </bookActionFetcher.Form>
                  )
                  .concat(
                    relateKeywords.length > 0
                      ? <div className='grid grid-flow-row gap-1 my-[auto]'>
                        {relateKeywords.map((keyword) => <RelateKeyword key={keyword.id} keyword={keyword} width={widthPx} />)}
                      </div>
                      : []
                  )
                  .concat(
                    _reviews.slice(4)
                      .map(review =>
                        <bookActionFetcher.Form action='/action-book' key={`${keyword.id}+${review.id}+${review.owner.id}`} method='POST'>
                          <Link prefetch='intent' to={`/books/${review.bookId}`}>
                            <LibraryItem
                              isLoading={bookActionFetcher.state === 'loading'}
                              keyword={keyword}
                              onClick={(id) => navigate(`/books/${id}`)}
                              review={review}
                              tag='user'
                              widthPx={widthPx}
                            />
                          </Link>
                        </bookActionFetcher.Form>
                      )
                  )
                  .concat(
                    paginationFetcher.state === 'loading' 
                      ? Array.from({ length: 20 }).map((_, index) => (
                        <section
                          className={
                            cn(
                              'w-[10.25rem] h-[15rem] mb-[auto]',
                              'relative shadow-2xl rounded-2xl border border-gray-20 bg-gray-20')
                          }
                          key={index}>
                          <div className={
                            cn('group grid content-between absolute rounded-xl')
                          } style={{height: '100%', width: '100%'}} />
                        </section>)
                      )
                      : []
                  )
                  .concat(...[
                    <paginationFetcher.Form className='h-[50px]' key={`${keyword.id}paginationFetcher`} ref={paginationFetcherRef}>
                      <input name='nextCursor' type='hidden' value={_reviews[_reviews.length -1]?.originId} />
                      <input name='library' type='hidden' value={keyword.name} />
                      <input name='sort' type='hidden' value={sort} />
                      <div className='h-20' ref={ref} />
                    </paginationFetcher.Form>
                  ])
              }
            </section>
          </div>
          : 
          <section className='grid py-[6.25rem] px-10 gap-5'>
            <EmptyDescription isMe={true} library='books' />
            {isLogged && (
              <div className='grid justify-center'>
                <UploadBookDialog type='button' /> 
              </div>
            )}
          </section>
      }
    </>
  );
};

export default OpenLibraryReviews;
